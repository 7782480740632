import React, { useState, useContext } from 'react';
import { Section } from '@/site/Section';


export const Sections = React.memo(({ sections, setSections }) => {
  
  // console.log("セクションズ", "レンダ");
  
  const sectionsState = { 
    sections, setSections
  };
  
  return (
    <>
      {sections.map((section, index) => {
        return (
          <section className="section" key={section.id}>
            <Section 
              section_id={section.id}
              sectionsState={sectionsState}
              index={index}
            />
          </section>
        );
      })}
    </>
  );
});
