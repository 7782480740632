import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { StateContext } from '@/providers/StateProviders';
import { ToastContext } from '@/providers/ToastProvider';
import { Nav } from '@/product/Nav';
import { Subject } from '@/product/Subject';
import { RemovePages } from '@/trashbox/RemovePages';


export const TrashBox = React.memo(() => {

  const { productId } = useParams();
  const { setToastObj } = useContext(ToastContext);
  const { client } = useContext(StateContext);
  
  const [ pages, setPages ] = useState([]);
  const [ sections, setSections ] = useState([]);
  const [ blocks, setBlocks ] = useState([]);
  const [ parts, setParts ] = useState([]);
  
  
  // 画面遷移のため
  const navigate = useNavigate();
  
  useEffect(() => {
	  client.get(`/api/v1/products/${productId}/trashbox`)
	  .then(resp => {
	    setPages(resp.data.pages);
	    setSections(resp.data.sections);
	    setBlocks(resp.data.blocks);
	    setParts(resp.data.parts);
	    console.log(resp.data);
	  })
	  .catch(e => {
	    navigate(`/home`);
	    setToastObj({message: "アクセスできません。", color: "#ffedab"});
	    console.log(e);
	  });
  }, []);
  
  
  const useDateTime = ( day ) => {
	  const date = new Date(day); 
	  // return date.getFullYear() + '年' + (date.getMonth() + 1) + '月' + date.getDate() + '日';
	  return date.toLocaleString();
	};

  
  // セクションを元に戻す
  const sectionRestore = (section) => {
    const sure = window.confirm(`（${section.id}）このセクションを元に戻しますか?`);
    if (sure) {
      client.patch(`/api/v1/sections/${section.id}`, { ...section, status: "公開" })
      .then(resp => {
        setSections((prev) =>
          prev.filter((obj) => obj.id !== section.id)
        );
      })
      .catch(e => {
        setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
        console.log(e);
      });
    }
  };
  
  
  // セクション完全削除
  const sectionDelete = (section) => {
    const sure = window.confirm(`（${section.id}）このセクションを完全削除しますか?`);
    if (sure) {
      client.delete(`/api/v1/sections/${section.id}`)
      .then(resp => {
        setSections((prev) =>
          prev.filter((obj) => obj.id !== section.id)
        );
      })
      .catch(e => {
        setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
        console.log(e);
      });
    }
  };


	// ブロックを元に戻す
  const blockRestore = (block) => {
    const sure = window.confirm(`（${block.id}）このブロックを元に戻しますか?`);
    if (sure) {
      client.patch(`/api/v1/blocks/${block.id}`, { ...block, status: "公開" })
      .then(resp => {
        setBlocks((prev) =>
          prev.filter((obj) => obj.id !== block.id)
        );
      })
      .catch(e => {
        setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
        console.log(e);
      });
    }
  };

  // ブロックの完全削除
  const blockDelete = (block) => {
    const sure = window.confirm(`（${block.id}）このブロックを完全削除しますか?`);
    if (sure) {
      client.delete(`/api/v1/blocks/${block.id}`)
      .then(resp => {
        setBlocks((prev) =>
          prev.filter((obj) => obj.id !== block.id)
        );
      })
      .catch(e => {
        setToastObj({message: "【エラー】削除に失敗しました。", color: "#ffedab"});
        console.log(e);
      });
    }
  };
  
  
  // パーツを元に戻す
  const partRestore = (part) => {
    const sure = window.confirm(`（${part.id}）このパーツを元に戻しますか?`);
    if (sure) {
      client.patch(`/api/v1/parts/${part.id}`, { ...part, status: "公開" })
      .then(resp => {
        setParts((prev) =>
          prev.filter((obj) => obj.id !== part.id)
        );
      })
      .catch(e => {
        setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
        console.log(e);
      });
    }
  };
  
  // パーツ完全削除
  const partDelete = (part) => {
    const sure = window.confirm(`（${part.id}）このパーツを完全削除しますか?`);
    if (sure) {
      client.delete(`/api/v1/parts/${part.id}`)
      .then(resp => {
        setParts((prev) =>
          prev.filter((obj) => obj.id !== part.id)
        );
      })
      .catch(e => {
        setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
        console.log(e);
      });
    }
  };

 

  return (
    <>
      <div className="main_layer"></div>
      <Nav/>
      <Subject/>

      <h1 className="H1 color_white pb_1">ゴミ箱</h1>
      <div className="color_white mb_1">
        30日経過すると自動で完全削除されます。
      </div>
      
      <h3 className="">ページ</h3>
      <RemovePages pages={pages} setPages={setPages}/>
      
      <h3 className="">セクション</h3>
      {sections.map((section, index) => {
        return (
          <div className="remove_item" key={section.id}>
          	<div>
	          	{section.status}｜ID：{section.id}｜削除日：{useDateTime(section.updated_at)}｜
	          </div>
	          <div>
	          	<button className="btn_delete" onClick={()=>sectionDelete(section)}>完全削除</button>
	          	<button className="btn" onClick={()=>sectionRestore(section)}>元に戻す</button>
	          </div>
	        </div>
        );
      })}
      
      <h3 className="">ブロック</h3>
      {blocks.map((block, index) => {
        return (
          <div className="remove_item" key={block.id}>
          	<div>
          		{block.status}｜ID：{block.id}｜削除日：{useDateTime(block.updated_at)}｜
          	</div>
          	<div>
	          	<button className="btn_delete" onClick={()=>blockDelete(block)}>完全削除</button>
	          	<button className="btn" onClick={()=>blockRestore(block)}>元に戻す</button>
          	</div>
          </div>
        );
      })}
      
      <h3 className="">パーツ</h3>
      {parts.map((part, index) => {
        return (
          <div className="remove_item" key={part.id}>
          	<div>
          		{part.status}｜ID：{part.id}｜削除日：{useDateTime(part.updated_at)}｜{part.component_name}｜
          	</div>
          	<div>
          		<button className="btn_delete" onClick={()=>partDelete(part)}>完全削除</button>
          		<button className="btn" onClick={()=>partRestore(part)}>元に戻す</button>
          	</div>
          </div>
        );
      })}

    </>
  );
});