import React, { useState, useContext } from 'react';
// import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { StateContext} from '@/providers/StateProviders';
import { ToastContext } from '@/providers/ToastProvider';
import { Section } from '@/section/Section';
// import { NewSection } from '@/section/NewSection';
// import { BiPlus } from "react-icons/bi";


export const Sections = React.memo(({ page_id, sections, setSections, sharing }) => {
  
  // console.log("セクションズ", "レンダ");
  
  const { client } = useContext(StateContext);
  const { setToastObj } = useContext(ToastContext);
  
  const sectionsState = { 
    sections, setSections
  };
  
  // const [ open, setOpen ] = useState("");
  // const OpenHandler = (sectionId)=> {
  //   setOpen(sectionId);
  // };
  
  
  //並び順を保存
  const OrderUpdate = (objs) => {
    setSections(objs);
    
    client.patch(`/api/v1/sections/order`, {sections: objs})
    .then(resp => {
      console.log("並び替え完了");
    })
    .catch(e => {
      setToastObj({message: "【エラー】並び替えの保存に失敗しました。", color: "#ffedab"});
    });
  };
  
  
  //並び順をUP
  const orderUp = (index) => {
    const objs = [...sections]
    const removed = objs.splice(index, 1);
    objs.splice(index-1, 0, removed[0]);
    OrderUpdate(objs);
  };
  
  const orderDown = (index) => {
    const objs = [...sections]
    const removed = objs.splice(index, 1);
    objs.splice(index+1, 0, removed[0]);
    OrderUpdate(objs);
  };
  
  

  return (
    <>
      {sections.map((section, index) => {
        return (
          <section className="section" key={section.id} id={`section_${section.id}`}>
            <Section 
              section_id={section.id}
              sectionsState={sectionsState}
              orderUp={orderUp}
              orderDown={orderDown}
              sharing={sharing}
              index={index}
              key={section.id}
            />
          </section>
        );
      })}
    </>
  );
});

{/*
{sharing == null &&
  <>
    <button className="btn_section_add" onClick={()=>OpenHandler(section.id)}>
      <span><BiPlus/>ブロックを追加</span>
    </button>
    { open == section.id &&
      <NewSection 
        page_id={page_id} 
        sections={sections} 
        setSections={setSections} 
        sharing={sharing}
        index={index}
        key={`NewSection-${section.id}`}
        setOpen={setOpen}
      />
    }
  </>
}
*/}