import React, { useEffect, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { ToastContext } from '@/providers/ToastProvider';
import { StateContext } from '@/providers/StateProviders';
import { BiPlus } from "react-icons/bi";
import { useForm } from 'react-hook-form';


export const NewProduct = ({ productsState, plansState }) => {
  
  // バリデーションフォーム
  const { 
    register, 
    handleSubmit,
    setValue,
    formState: { errors }, 
  } = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
  });
  
  
  const { setToastObj } = useContext(ToastContext);
  const { client, currentUser } = useContext(StateContext);
  
  const { products, setProducts } = productsState
  const { plans } = plansState
  
  // 画面遷移のため
  const navigate = useNavigate();
  
  // 有料プラン数が足りたい場合、リダイレクト
  useEffect(() => {
    if(plans.length < products.length && currentUser.admin !== true) {
      navigate('/home');
      setToastObj({message: "プランの変更が必要です。", color: "#ffedab"});
      return;
    };
    
    // setValue("subdomain", `${SecureRandom.alphanumeric(8)`);
  }, []);
  
  
  // 作成
  const CreateProduct = (data) => {
    
    if (/www|magazine|company|guide|pagestock|sample/.test(data.subdomain)) {
      setToastObj({message: "プロダクトIDに使用できない文字が含まれています。", color: "#ffedab"});
      return;
    }
    
    client.post("/api/v1/products", data)
    .then(resp => {
      setProducts([resp.data.product, ...products]);
      navigate('/home');
      setToastObj({message: "プロダクトを作成しました", color: "#D1E7DD"});
    })
    .catch(e => {
      setToastObj({message: `【失敗】${e.response.data}`, color: "#ffedab"});
      console.log(e);
    });
  };


  return (
    <>
      <h1 className="H1">プロダクトの作成</h1>
      <div className="base_box">
        
        <form onSubmit={handleSubmit(CreateProduct)}>
        
          <div className="form_field">
            <label>プロダクトID（※初期のサイトURLになります。）</label>
            
            <div className="productid_input_box">
              <span>https://</span>
              <input 
                id="subdomain"
                className={`input ${errors.subdomain?.message && "error"}`}
                type="text"
                placeholder="半角英数字を入力..."
                {...register('subdomain', {
                  required: { value: true, message: '【エラー】必須項目です。'},
                  minLength: { value: 5, message: '【エラー】5文字以上で入力してください。'},
                  maxLength: { value: 50, message: '【エラー】50文字以内で入力してください。'},
                  pattern: { value: /^[a-z0-9\-]+$/, message: '【エラー】半角英数字と「-」のみ使用可'},
                })}
              />
              <span>.pstock.me</span>
            </div>
            
            {errors.subdomain?.message && 
              <div className="color_red">{errors.subdomain.message}</div>
            }
            <p>このプロダクトIDが、ドメイン「〇〇〇.pstock.me」の「〇〇〇」部分に設定されます。<br/>
            ※半角英数字と「-」のみ使用可。<br/>
            ※変更するとサイトURLが変更されますのでご注意ください。<br/>
            ※独自ドメインを設定すると、この初期URLではなく独自ドメインに切り替わります。</p>
          </div>

          <div className="form_field">
            <label>サイト名</label>
            <input 
              id="name"
              className={`input ${errors.name?.message && "error"}`}
              type="text"
              placeholder="サイト名を入力..."
              {...register('name', {
                required: { value: true, message: '【エラー】必須項目です。'},
                maxLength: { value: 40, message: '【エラー】40文字以内で入力してください。'},
              })}
            />
            {errors.name?.message && 
              <div className="color_red">{errors.name.message}</div>
            }
            <p>サイト名を決めてください。後で「変更」も可能です。</p>
          </div>
        
          <button type="submit" className="btn_add">
            <span><BiPlus/>プロダクトを作成</span>
          </button>
          
        </form>
        
      </div>
      <center>
        <Link to="/home">
          <button className="btn_edit">戻る</button>
        </Link>
      </center>
    </>
  );
};


// <label>サブドメイン</label>
// <input 
//   className="input" 
//   type="text" 
//   name="subdomain" 
//   value={product.subdomain} 
//   onChange={InputChange} 
// />

// <label>サイト名</label>
// <input 
//   className="input" 
//   type="text" 
//   name="name" 
//   value={product.name} 
//   onChange={InputChange} 
// />

// <button className="btn_add" onClick={saveProduct}>
//   <span><BiPlus/>プロダクトを作成</span>
// </button>