import React, { useContext } from 'react';
import { ToastContext } from '@/providers/ToastProvider';
import { StateContext } from '@/providers/StateProviders';


export const SectionRemove = React.memo(({ selectSection, section, setSections, setModal }) => {
  
  const { client } = useContext(StateContext);
  const { setToastObj } = useContext(ToastContext);

  // セクション完全削除
  // const deleteSection = () => {
  //   const sure = window.confirm(`このセクションを本当に削除しますか?`);
  //   if (sure) {
  //     client.delete(`/api/v1/sections/${section.id}`)
  //     .then(resp => {
  //       setSections((prev) =>
  //         prev.filter((obj) => obj.id !== section.id)
  //       );
  //     })
  //     .catch(e => {
  //       setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
  //       console.log(e);
  //     });
  //   }
  // };
  

  // セクション一時削除
  const removeSection = () => {
    const sure = window.confirm(`このセクションを本当に削除しますか?`);
    if (sure) {
      client.patch(`/api/v1/sections/${section.id}`, { ...section, status: "削除" })
      .then(resp => {
        setSections((prev) =>
          prev.filter((obj) => obj.id !== section.id)
        );
      })
      .catch(e => {
        setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
        console.log(e);
      });
    }
  };
  

  return (
  　<div className="modal modal_delete" onClick={()=>setModal(false)}>
      <div className="modal_window" onClick={(e) => e.stopPropagation()}>
        <div className="modal_content">
          {selectSection()}
        </div>
        <div className="modal_btn_area">
          <button className="back_btn" onClick={()=>setModal(false)}>もどる</button>
          <button className="delete_btn" onClick={removeSection}>削除する</button>
        </div>
      </div>
    </div>
  );
});