import React, { useState, useEffect, useContext, useRef } from "react"
import { Link, useParams } from 'react-router-dom';
import { StateContext } from '@/providers/StateProviders';
import { ToastContext } from '@/providers/ToastProvider';
import { Part } from '@/part/Part';
import { BlockEdit } from '@/block/BlockEdit';
import { BlockSplide } from '@/block/BlockSplide';
import { imgCSS } from '@/part/type/Style';
import { useAutoSave } from '@/hooks/useAutoSave';


/** @jsx jsx */
import { jsx, css } from "@emotion/react";

import { useRecoilState, useSetRecoilState } from 'recoil'
import { saveStatusAtom, blockStatesAtom, blockImgStatesAtom } from '@/providers/Atoms';


export const Block = React.memo(({block_obj, blocksState, section_id, slide, index}) => {
  
  // console.log("ブロックトップ", "レンダ",`${block_obj.id}`);
  
  const { productId, blockId } = useParams();
  const { client } = useContext(StateContext);
  const { setToastObj } = useContext(ToastContext);
  
  // const setBlockStates = useSetRecoilState(blockStatesAtom)
  const [ blockStates, setBlockStates ] = useRecoilState(blockStatesAtom);
  const setBlockImgStates = useSetRecoilState(blockImgStatesAtom)
  const setSaveStatus = useSetRecoilState(saveStatusAtom);
  
  // const [ clickBlock, setClickBlock ] = useRecoilState(clickBlockAtom)
  
  // const { saveData, setSaveData } = useContext(SaveDataContext);
  // const [ saveData, setSaveData ] = useRecoilState(saveDataAtom);
  
  const { setBlocks } = blocksState;
  const [ block, setBlock ] = useState([]);
  //const blockState = { block, setBlock }
    
  const [ parts, setParts ] = useState([]);
  const partsState = { parts, setParts }
  
  const [ img, setImg ] = useState([])
  //const imgState = { img, setImg }
  
  
  useEffect(() => {
    client.get(`/api/v1/blocks/${block_obj.id}`)
    .then(resp => {
      setBlock(resp.data.block);
      setParts(resp.data.parts);
      setImg(resp.data.imgs[0] || {block_id: block_obj.id, img: null});
      // console.log(resp.data);
    })
    .catch(e => {
      console.log(e);
    });
  }, []);
  
  // console.log(img.img);
  
  
  // 保存
  const updateBlock = () => {
    client.patch(`/api/v1/blocks/${block.id}`, block)
    .then(resp => {
      // setToastObj({message: "保存しました。", color: "#D1E7DD"});
      setSaveStatus(false);
      console.log("保存完了");
    })
    .catch(e => {
      setToastObj({message: "【エラー】保存に失敗しました。", color: "#ffedab"});
      // setToastObj({message: `【失敗】${e.response.data}`, color: "#ffedab"});
      console.log(e);
    });
  };
  
  // オートセーブ
  useAutoSave(block, updateBlock, 1000);
  
  
  // 共有ブロックを外す
  const offSharing = () => {
    const sure = window.confirm(`この共有ブロックを本当に除外しますか?`);
    if (sure) {
      client.post(`/api/v1/blocks/off_block`, {section_id: section_id, block_id: block.id})
      .then(resp => {
        setBlocks((prev) =>
          prev.filter((obj) => obj.id !== block.id)
        );
        setToastObj({message: "共有ブロックを除外しました。", color: "#D1E7DD"});
      })
      .catch(e => {
        setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
        console.log(e);
      });
    }
  };

  
  // クリックしたブロックをセット
  const BlockClickHandler = () => {
    // setClickBlock(block.id);
    setBlockImgStates([ img, setImg ]);
    setBlockStates([ block, setBlock ]);
  }
  
  const [ active, setActive ] = useState(true);
  const [ display, setDisplay ] = useState("スライド編集");
  
  const StopHandler = ()=> {
    setActive((prev)=> !prev);
    const newDisplay = active ? "編集終了" : "スライド編集"
    setDisplay(newDisplay);
    // setPickedPart("");
    // setClickBlock("");
  };
  
  // const blockCss = css`
  //   background-color: ${block?.color && block.color};
  //   background-image: ${block?.img && `url(${block.img})` || block?.sub_color && `linear-gradient(${block.deg || "0"}deg, ${block.color || "#fff"} ${block.color_stop || "0"}%, ${block.sub_color || "#fff"})`};
  //   padding: ${block?.padding && `${block.padding}px 0`};
  //   background-position: center center;
  //   background-size: cover;
  //   background-repeat: repeat-y;
  //   min-height: ${block?.height && `${block.height}vh`};
    
  //   @media screen and (max-width: 500px) {
  //     padding: ${block?.padding && `calc(${block.padding}px *0.6) 0`};
  //   }
  // `
  
  // const deg = block.deg || block.deg == 0 && 0
  
  
  let paddings = block.padding ? block.padding.split("／") : ["",""];

  const blockCss = css`
    min-height: ${block?.height && `${block.height}vh`};
  
    padding-top: ${paddings[0] && `${paddings[0]}px`};
    padding-bottom: ${paddings[1] && `${paddings[1]}px`};
    
    @container (width < 500px) {
      padding-top: ${paddings[0] && `calc(${paddings[0]}px *0.6)`};
      padding-bottom: ${paddings[1] && `calc(${paddings[1]}px *0.6)`};
    }
  `
  
  const blockLayerCss = css`
    ${img.img && imgCSS(img)}
    
    :before {
      background-color: ${block.color};
      background-image: ${block?.sub_color && `linear-gradient(${block.deg || "0"}deg, ${block.color || "transparent"} ${block.color_stop || "0"}%, ${block.sub_color || "#fff"})`};
    }
  `
  
  // let Gap;
  // if(block.column == 2) {
  //   Gap = "13px 33px";
  // } else if(block.column == 3) {
  //   Gap = "13px 33px";
  // } else if(block.column == 4) {
  //   Gap = "8px 28px";
  // } else if(block.column == 5) {
  //   Gap = "5px 25px";
  // } else if(block.column == 6) {
  //   Gap = "0px 18px";
  // }
  
  let Gap;
  if(block.column == 2) {
    Gap = "15px 35px";
  } else if(block.column == 3) {
    Gap = "15px 35px";
  } else if(block.column == 4) {
    Gap = "10px 30px";
  } else if(block.column == 5) {
    Gap = "5px 25px";
  } else if(block.column == 6) {
    Gap = "0px 20px";
  }

  let Gap_s;
  if(block.column_s == 2) {
    Gap_s = "0 2rem";
  } else if(block.column_s == 3) {
    Gap_s = "0 1.5rem";
  } else if(block.column_s == 4) {
    Gap_s = "0 1.5rem";
  } else if(block.column_s == 5) {
    Gap_s = "0 1rem";
  }
  
  const wrapperCss = css`
    gap: ${Gap};
    max-width: ${block?.wrapper && `${block.wrapper}px`};
    padding: ${block.wrapper >= 2000 && 0 };

    @container (width < 500px) {
      gap: ${Gap_s ||0};
    }

    ${ slide == "flowing" && block?.column > 1 && `
      flex-wrap: nowrap;
      justify-content: space-around;
      max-width: 100%;
      padding: 0 ${Gap.substr(5, 2)/2}px;
      
      .part_box {
        margin: 0;
      }
      
      @container (width < 500px) {
        padding: 0;
        gap: 0;
        
        .part_box {
          width: 65cqw;
          margin-left: 10px;
          margin-right: 10px;
        }
      }
    `}
  `
  
    // ${ slide == "splide" && block?.column > 1 && `
    //   flex-wrap: nowrap;
    //   justify-content: space-around;
    //   max-width: 100%;
    //   padding: 0;
    //   gap: 0;
        
    //   @media screen and (max-width: 500px) {
    //     .part_box {
    //       margin-left: 5px;
    //       margin-right: 5px;
    //     }
    //   }
    // `}


  return (
    <>
      { parts.length > 0 &&
        <div 
          className={`block_area ${block.add_class}`}
          css={blockCss}
          id={block.add_id}
          onClick={BlockClickHandler}
        >
          <div 
            className={`block_layer ${blockStates[0]?.id == block.id && "picked"}`}
            css={blockLayerCss}
          >
            {img.img &&
              <>
                { /.mp4|.ogv|.webm/.test(img.img)
                  ? <video src={img.img} autoPlay loop muted playsInline></video>
                  : <img src={img.img} alt={img.alt ||""}/>
                }
              </>
            }
          </div>
          
          { block?.block_type == null || /fixed/.test(block.block_type)
           ? "" : <button className="switch_stop_btn" onClick={StopHandler}>{display}</button>
          }
  
          <div 
            className="block_wrapper"
            css={wrapperCss}
            // style={{maxWidth: block?.wrapper && `${block.wrapper}px`}}
          >
            { block?.block_type == null || /fixed/.test(block.block_type) || !active
              ?
                <>
                  {parts.map((part, index) => {
                    return (
                      <Part 
                        part_obj={part} 
                        partsState={partsState}
                        column={block.column}
                        column_s={block.column_s}
                        slide={false} 
                        index={index} 
                        key={part.id}
                      />
                    );
                  })}
                </>
              : <BlockSplide block={block} partsState={partsState}/>
            }
          </div>
          
          {block.status == "非公開" && 
            <div className="inactive">非公開</div>
          }
          
          {/fixed/.test(block.block_type) &&
            <>
              <div className="block_fixed">固定済み</div>
              <style>{`#section_${section_id}_delete_btn {display: none;}`}</style>
            </>
          }
          
          { !blockId &&
            <>
              {block.sharing == "sharing" && 
                <div className="sharing">
                  <div className="sharing_inner">
                    <p className="mb_2">共有ブロック</p>
                    <button className="mb_1" onClick={offSharing}>共有ブロックを外す</button>
                    <p>削除されるわけではありません。<br/>共有ブロックがこのページで表示されなくなるだけです。</p>
                    <Link to={`/product/${productId}/block/${block.id}`} className="color_key">編集・削除はこちら</Link>
                  </div>
                </div>
              }
            </>
          }
        </div>
      }
    </>
  );
  
});


  //     {/* 
		// { clickBlock == block.id && 
		// 	<BlockEdit
		// 	  blockState={blockState}
		// 	  blocksState={blocksState}
		// 	  imgState={imgState}
		// 	  section_id={section_id}
		// 	/>
		// }
	 // */}


// <BlockEdit
//   blockState={blockState}
//   InputChange={InputChange} 
//   updateBlock={updateBlock} 
//   deleteBlock={deleteBlock}
//   colorState={colorState}
//   Reset={Reset}
//   Clear={Clear}
// />


// <div className={`${ColumnClass}`} key={index}>
//   <PartProvider key={index}>
//     <Part part_obj={part} column={block.column} index={index} key={part.id}/>
//   </PartProvider>
// </div>

// className={`block_area ${part_ids.includes(pickedPart) && "picked"}`}
// className={`block_area ${pickedPart == `block_${block.id}` && "picked"}`}


  // ブロックのカラム数に応じてCSS変更
  // let ColumnClass;
  // if (block.column == null || block.column == 1) {
  //   ColumnClass = "block_column_1";
  // } else if(block.column == 2) {
  //   ColumnClass = "block_column_2";
  // } else if(block.column == 3) {
  //   ColumnClass = "block_column_3";
  // } else if(block.column == 4) {
  //   ColumnClass = "block_column_4";
  // } else {
  //   ColumnClass = "block_column_1";
  // }
  
  // { part_ids.includes(pickedPart) && 
  //       <BlockEdit
  //         blockState={blockState}
  //         blocksState={blocksState}
  //       />
  //     }