import React, { useState, useEffect, useContext, useCallback } from "react"
import axios from 'axios'
// import { useInView } from 'react-intersection-observer';
// import { BiX } from "react-icons/bi";
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { StateContext } from '../providers/StateProviders';
import { ToastContext } from '@/providers/ToastProvider';
import { PartEdit } from '@/part/PartEdit';
import { PartSubmenu } from '@/part/PartSubmenu';
// import { PartAdd } from '@/part/PartAdd';
// import { ImgSelect } from '@/image/ImgSelect';
// import { PartChange } from '@/part/PartChange';
import { PartComponents } from '@/part/PartComponents';
import { useAutoSave } from '@/hooks/useAutoSave';

import { useRecoilState, useSetRecoilState } from 'recoil'
import { saveStatusAtom, partStatesAtom } from '@/providers/Atoms';


export const Part = React.memo(({part_obj, partsState, column, column_s, slide, index}) => {
  
  // console.log("パーツトップ", "レンダ", `${part_obj}`);
  
  // const { ref, inView } = useInView({
  //   rootMargin: '0px', // ref要素が現れてから50px過ぎたら
  //   // triggerOnce: true, // 最初の一度だけ実行
  // });
  
  const [ partStates, setPartStates ] = useRecoilState(partStatesAtom);
  // const setPartStates = useSetRecoilState(partStatesAtom)
  
  const { client } = useContext(StateContext);
  const { setToastObj } = useContext(ToastContext);
  
  // const { parts, setParts } = partsState;
  const [ part, setPart ] = useState([]);
  const partState = { part, setPart }
  
  const [ texts, setTexts ] = useState([]);
  const [ imgs, setImgs ] = useState([]);
  // const [ imgs_sp, setImgs_sp ] = useState([]);
  // const imgsState = { imgs, setImgs }
  
  // const setSaveData = useSetRecoilState(saveDataAtom);
  const setSaveStatus = useSetRecoilState(saveStatusAtom);
  
  // const [ clickPart, setClickPart ] = useRecoilState(clickPartAtom);
  // const setClickBlock = useSetRecoilState(clickBlockAtom);

  const [ isLoading, setIsLoading ] = useState(true);
  const [ open, setOpen ] = useState("");
  const openState = { open, setOpen }
  
  // const [ title, setTitle ] = useState([]);
  // const [ subtitle, setSubtitle ] = useState([]);
  // const [ content, setContent ] = useState([]);
  
  useEffect(() => {
    axios.get(`/api/v1/parts/${part_obj.id}`)
    .then(resp => {
      setPart(resp.data.part);
      setTexts(resp.data.texts);
      setImgs(resp.data.imgs);
      // setImgs_sp(resp.data.imgs_sp);
      // setTitle(resp.data.texts.find((obj) => obj.text_type === "title"));
      // setSubtitle(resp.data.texts.find((obj) => obj.text_type === "subtitle"));
      // setContent(resp.data.texts.find((obj) => obj.text_type === "content"));
      setIsLoading(false);
    })
    .catch(e => {
      console.log(e);
    });
  }, []);
  
  // console.log(part);


  // セーブデータの更新
  // const SaveDataUpdate = useCallback((newData) => {
  //   setSaveData((prev)=> ({ 
  //   ...prev, 
  //     parts: prev.parts.map((obj) => (
  //       obj.id === part.id ? newData : obj
  //     ))
  //   }));
  // }, [part]);

  
  const InputChange = event => {
    const { name, value } = event.target;
    setPart((prev)=> ({ ...prev, [name]: value }));
    
    // const newPart = {...part, [name]: value };
    // setPart(newPart);
    // SaveDataUpdate(newPart);
  };

  
  // 公開する
  const Release = () => {
    setPart((prev)=> ({ ...prev, status: "公開" }));
    
    // const  newPart = {...part, status: "公開"};
    // setPart(newPart);
    // SaveDataUpdate(newPart);
  }
  
  
  // クリア
  const Clear = (name) => {
    setPart((prev)=> ({ ...prev, [name]: null }));
    
    // const newPart = {...part, [name]: null};
    // setPart(newPart);
    // SaveDataUpdate(newPart);
  }
  
  
  // クリックしたパーツをセット
  const ClickHandler = () => {
    // setClickPart(part.id);
    setPartStates([ part, setPart ]);
  }
  
  const CloseHandler = (e)=> {
    e.stopPropagation();
    // setPickedPart("");
    // setClickPart("");
    // setClickBlock("");
    
    setPartStates([]);
  };
  

  // 保存
  const updatePart = () => {
    client.patch(`/api/v1/parts/${part.id}`, part)
    .then(resp => {
      // setToastObj({message: "保存しました。", color: "#D1E7DD"});
      setSaveStatus(false);
      console.log("保存完了");
    })
    .catch(e => {
      setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
      console.log(e);
    });
  };
  
  // オートセーブ
  useAutoSave(part, updatePart, 3000);


  // ブロックのカラム数に応じてCSS変更
  let Col = "col_1";
  if(column == 2) {
    Col = "col_2";
  } else if(column == 3) {
    Col = "col_3";
  } else if(column == 4) {
    Col = "col_4";
  } else if(column == 5) {
    Col = "col_5";
  } else if(column == 6) {
    Col = "col_6";
  }

  let Col_s = "100%";
  if(column_s == 2) {
    Col_s = "calc((100% - 2rem) / 2)";
  } else if(column_s == 3) {
    Col_s = "calc((100% - 3rem) / 3)";
  } else if(column_s == 4) {
    Col_s = "calc((100% - 4.5rem) / 4)";
  } else if(column_s == 5) {
    Col_s = "calc((100% - 4rem) / 5)";
  }
  

  const headlineJudge = /headline_4/.test(part.class_name)
  let margins = part.margin ? part.margin.split("／") : [];
  
  // const img_0 = imgs[0] || {part_id: part.id, img: null, radius: null,};
  // console.log("画像", img_0.radius);
  
  const partCSS = css`
    width: ${slide && "100%" || part.width && `${part.width}%`};
    height: ${part.height && `${part.height}px`};
    
    margin: ${part.position == "left" && `10px auto 10px 0` || part.position == "center" && `10px auto 10px auto` || part.position == "right" && `10px 0 10px auto`};
    margin: ${headlineJudge && `0`};
    margin-top: ${margins[0] && `${margins[0]}px`};
    margin-bottom: ${margins[1] && `${margins[1]}px`};
    
    position: ${part.layer_position && "absolute"};
    top: ${part.layer_position == "top" && `0`};
    right: ${part.layer_position == "right" && `0`};
    
    @container (width < 500px) {
      width: ${part.width && "100%" || Col_s || "100%"};
    }
  `
  // width: ${part.width && "100%"};

  // margin-bottom: ${part.margin && `${part.margin}px`};
  // margin-top: ${part.margin == 0 && 0 };
  
  // ${ part.class_name == "point_2-1" && img_0.radius && `
  //     &:nth-of-type(2n) .point_2-1 .point_img img {
  //       border-radius: 0 ${img_0.radius}px ${img_0.radius}px 0;
  //     }
  //   `} 
    
  return (
    <>
      { isLoading ? "" :
        <>
          <div 
            className={`part_box ${Col} ${part.add_class} ${partStates[0]?.id == part.id && "picked"}`}
            css={partCSS}
            id={part.add_id}
            onClick={ClickHandler}
          >
            <PartComponents
              partState={partState}
              texts={texts}
              imgs={imgs}
              // imgs_sp={imgs_sp}
              InputChange={InputChange}
              openState={openState}
              Clear={Clear}
              client={client}
            />
            
            {part.status == "非公開" && 
              <div className="inactive">
                非公開
                {/*<button onClick={Release}>公開する</button>*/}
              </div>
            }
            
            {partStates[0]?.id == part.id &&
              <PartSubmenu
                partState={partState}
                partsState={partsState}
                column={column}
                InputChange={InputChange}
                updatePart={updatePart}
                // SaveDataUpdate={SaveDataUpdate}
                // OrderUpdate={OrderUpdate}
                CloseHandler={CloseHandler}
                openState={openState}
                Clear={Clear}
                index={index}
              />
            }
          </div>

        </>
      }
    </>
  );
});


{/*
<PartEdit
  partState={partState}
  partsState={partsState}
  openState={openState}
  InputChange={InputChange}
  updatePart={updatePart}
  // changeOnMouse={changeOnMouse}
  // PartTypeChange={PartTypeChange}
  // SaveDataUpdate={SaveDataUpdate}
  // OrderUpdate={OrderUpdate}
  CloseHandler={CloseHandler}
  index={index}
/>
*/}


{/*
{ open == "Type" &&
  <PartChange 
    partState={partState}
    // SaveDataUpdate={SaveDataUpdate}
    updatePart={updatePart}
    setOpen={setOpen}
  />
}

{ open == "Img" &&
  <ImgSelect 
    obj={part}
    setObj={setPart}
    InputChange={InputChange}
    // changeOnMouse={changeOnMouse}
    update={updatePart}
    SaveDataUpdate={SaveDataUpdate}
    Clear={Clear} 
    type="part"
    setOpen={setOpen}
  />
}
*/}


  //並び順のセーブデータ更新
  // const SaveOrderUpdate = (objs) => {
  //   const new_objs = objs.map((obj, index) => {
  //     return { ...obj, order: index }
  //   })
    
  //   setParts(new_objs);//並び替え後をセット

  //   new_objs.map((newObj) => (
  //     setSaveData((prev)=> ({ 
  //     ...prev, 
  //       parts: prev.parts.map((part) => (
  //         newObj.id === part.id ? newObj : part
  //       ))
  //     }))
  //   ))
  //   console.log(parts);
  // };
  

  // const PartTypeChange = event => {
  //   const { name, value } = event.target;
  //   let type = value.split(" ")
  //   const newPart = { ...part, component_name: type[0], class_name: type[1] }
  //   setPart(newPart);
  //   SaveDataUpdate(newPart);
  // };

      // {pickedPart == part_obj.id && 
      //   <div className="cover" onClick={CloseHandler}/>
      // }
// style={{width: part.width && `${part.width}%`}}
// style={{flexBasis: part.width && `${part.width}px`, flexGrow: part.width && 1, flexShrink: part.width && 10}}