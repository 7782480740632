import React, { useState, useEffect, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import { StateContext } from '@/providers/StateProviders';
import { ToastContext } from '@/providers/ToastProvider';
import { useDateTime } from '@/hooks/useDateTime';
import { Url } from '@/app/Url';

export const RemovePages = React.memo(({ pages, setPages }) => {
	
	const { productId } = useParams();
  const { setToastObj } = useContext(ToastContext);
  const { client } = useContext(StateContext);
	
	// let newDate = new Date();
	// console.log(newDate);
  
  // ページツを元に戻す
  const pageRestore = (page) => {
    const sure = window.confirm(`このページ「${page.title || "ページタイトル未設定"}」を元に戻しますか?`);
    if (sure) {
      client.patch(`/api/v1/pages/${page.id}`, { ...page, status: "公開" })
      .then(resp => {
        setPages((prev) =>
          prev.filter((obj) => obj.id !== page.id)
        );
      })
      .catch(e => {
        setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
        console.log(e);
      });
    }
  };
  
  // ページの完全削除
  const pageDelete = (page) => {
    const sure = window.confirm(`このページ「${page.title || "ページタイトル未設定"}」を完全に削除しますか?`);
    if (sure) {
      client.delete(`/api/v1/pages/${page.id}`)
      .then(resp => {
        setPages((prev) =>prev.filter((obj) => obj.id !== page.id));
        setToastObj({message: "ページを削除しました。", color: "#D1E7DD"});
      })
      .catch(e => {
        setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
        console.log(e);
      });
    }
  };

  return (
	  <div className="page_card_box">
	    {pages.map((page, index) => {
	      return (
	        <div className="page_card" key={page.id}>
			      <div className="card_img">
			        <img src={page.img || "/no_img.png"}/>
			      </div>
			      
			      <div className="card_body">
			        <div className="card_title">{page.title || "ページタイトル未設定"}</div>
			        <div className="badge badge_purple">{page.status}</div>
			        
			        {!page.index &&
			          <div className="badge badge_purple">ノーインデックス</div>
			        }
			        
			        {page.toppage &&
			          <div className="badge badge_key">トップページ</div>
			        }
			        
			        <div className="badge">
			          <Url slug={page.slug}/>
			        </div>
			        
			        <div className="btn_box">
			          <div className="date_box">
			            <div className="">作成日：{useDateTime(page.created_at)}</div>
			          </div>
			          
	            	<button className="btn_delete mr_1" onClick={()=>pageDelete(page)}>完全削除</button>
	            	<button className="btn" onClick={()=>pageRestore(page)}>元に戻す</button>
	            	
			          <Link to={`/product/${productId}/${page.slug}/edit`}>
			            <button className="btn_edit">ページの編集</button>
			          </Link>
			        </div>
			        
			      </div>
			    </div>
	      );
	    })}
	  </div>
  );
});