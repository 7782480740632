import React from 'react'
import { Link } from 'react-router-dom';
import { BiImages, BiSpreadsheet, BiCog, BiGridAlt, BiChevronRight } from "react-icons/bi";
import { useDateTime } from '@/hooks/useDateTime';
import { Url } from '@/app/Url';

export const ProductCard = React.memo(({ product }) => {

  return (
    <div 
      className="product_card" 
      style={{background: product.status == "非公開" && "#F6F7F9"}}
    >
      <div className="card_img">
        <img src={product.img || "/no_img.png"}/>
      </div>
      
      <div className="card_body">
      
        <div className="card_title">{product.name || "サイトネーム未設定"}</div>
        
        {product.status == "非公開" &&
          <div className="badge badge_purple">非公開</div>
        }
        {product.status == "削除" &&
          <div className="badge badge_purple">削除</div>
        }
        
        {!product.index &&
          <div className="badge badge_purple">ノーインデックス</div>
        }
        <div className="badge">ID／{product.subdomain}</div>
        <div className="badge"><Url product_obj={product}/></div>
        <div className="badge">{product.plan || "フリープラン"}</div>
        
        
        <div className="btn_box">
          <div className="date_box">
            <div className="">作成日：{useDateTime(product.created_at)}</div>
            
            <div className="drop_btn">
              <button className="btn_icon"><BiCog/></button>
              
              <div className="drop_box">
                <div className="drop_list">
                  <Link to={`/product/${product.subdomain}/menus`} className="drop_item">
                    <span><BiSpreadsheet/>メニュー</span>
                  </Link>
                  <Link to={`/product/${product.subdomain}/images`} className="drop_item">
                    <span><BiImages/>画像</span>
                  </Link>
                  <Link to={`/product/${product.subdomain}/blocks`} className="drop_item">
                    <span><BiGridAlt/>ブロック</span>
                  </Link>
                  <Link to={`/product/${product.subdomain}/setting`} className="drop_item">
                    <span><BiCog/>設定</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          
          <Link to={`/product/${product.subdomain}/dashboard`}>
            <button className="btn_edit">ダッシュボード<BiChevronRight/></button>
          </Link>
        </div>
      </div>
    </div>      
  );
});

// <div className="btn_box">
//   <Link to={`/product/${product.subdomain}/menus`} className="btn btn_text">
//     <span><BiSpreadsheet/>メニュー</span>
//   </Link>
//   <Link to={`/product/${product.subdomain}/images`} className="btn btn_text">
//     <span><BiImages/>画像</span>
//   </Link>
//   <Link to={`/product/${product.subdomain}/blocks`} className="btn btn_text">
//     <span><BiGridAlt/>ブロック</span>
//   </Link>
//   <Link to={`/product/${product.subdomain}/setting`} className="btn btn_text">
//     <span><BiCog/>設定</span>
//   </Link>
//   <Link to={`/product/${product.subdomain}/dashboard`} className="btn btn_line">
//     <span><BiNews/>ダッシュボード</span>
//   </Link>
// </div>