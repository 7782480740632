import React, { useState, useEffect, useContext} from 'react';
import { Routes, Route } from 'react-router-dom';
import axios from 'axios';
// import { ToastContainer, toast } from 'react-toastify';
// import "react-toastify/dist/ReactToastify.css"; 
import { StateContext } from '@/providers/StateProviders';

import { Nabvar } from '@/layout/Nabvar';
import { Sidebar} from '@/layout/Sidebar';
import { Footer} from '@/layout/Footer';
import { Dashboard } from '@/layout/Dashboard';
// import { Site } from '@/Site';
import { NoMatch } from '@/app/NoMatch';
import { Product } from '@/product/Product';
import { Products } from '@/product/Products';
import { Users } from '@/user/Users';
import { NewProduct } from '@/product/NewProduct';
import { ProductSetting } from '@/product/ProductSetting';
import { Images } from '@/image/Images';
import { FreeImages } from '@/image/FreeImages';
// import { Layout } from '@/product/Layout';
import { Page } from '@/page/Page';
import { PagePreview } from '@/page/PagePreview';
import { PageEditRedirect } from '@/page/PageEditRedirect';
import { Menus } from '@/menu/Menus';
import { ShareBlocks } from '@/sharing/ShareBlocks';
import { ShareBlock } from '@/sharing/ShareBlock';
import { HeaderSections } from '@/sharing/HeaderSections';
import { FooterSections } from '@/sharing/FooterSections';
// import { ContactSections } from '@/sharing/ContactSections';
import { Account } from '@/user/Account';
import { Billing } from '@/user/Billing';
import { CardSetting } from '@/user/CardSetting';
import { Contact } from '@/contact/Contact';
import { Inquiry } from '@/contact/Inquiry';
import { ProductContact } from '@/contact/ProductContact';
// import { CheckoutForm } from '@/payment/CheckoutForm';
import { Payment } from '@/payment/Payment';
import { Plans } from '@/plan/Plans';
import { Addons } from '@/addon/Addons';
import { ProductAddons } from '@/addon/ProductAddons';
import { Templates } from '@/template/Templates';
import { Manage } from '@/manage/Manage';
import { SendMail } from '@/manage/SendMail';
import { Notices } from '@/notice/Notices';
import { Notice } from '@/notice/Notice';
import { NewNotice } from '@/notice/NewNotice';
import { EditNotice } from '@/notice/EditNotice';
import { CustomForms } from '@/customForm/CustomForms';
import { TrashBox } from '@/trashbox/TrashBox';

// ログアウト時
import { Toppage } from '@/site/Toppage';
import { Guide } from '@/info/Guide';
import { Question } from '@/info/Question';


export const App = React.memo((props) => {
  
  // console.log("APPレンダリング");
  
  const { setCurrentUser, setToken , setS3key, setStripekey } = useContext(StateContext);
  
  const [ isLogin, setIsLogin ] = useState(false);
  const [ products, setProducts ] = useState([]);
  const productsState = { products, setProducts }
  const [ plans, setPlans ] = useState([]);
  const plansState = { plans }
  const [ notices, setNotices ] = useState([]);
  
  useEffect(() => {
    // setS3key(props);
    axios.get('/api/v1/login_user')
      .then(resp => {
        setIsLogin(resp.data.is_login)
        setCurrentUser(resp.data.login_user);
        setProducts(resp.data.user_products);
        setPlans(resp.data.user_plans);
        setNotices(resp.data.notices);
        setS3key(resp.data.aws_key);
        setStripekey(resp.data.stripe_key);
        setToken(resp.headers["x-csrf-token"]);
      });
  }, []);
  
  
  const [ menuOpen, setMenuOpen ] = useState(false);
  

  return (
    <>
      {isLogin ?
        <>
          <Nabvar menuOpen={menuOpen} setMenuOpen={setMenuOpen}/>
          
          <div className="container">
            <Sidebar menuOpen={menuOpen} setMenuOpen={setMenuOpen}/>
            
            <div className="main">
              <div className="main_area">
                
                <Routes>
                  <Route path="*" element={<NoMatch />} />
                  <Route path="manage" element={<Manage />} />
                  <Route path ="home" element = {<Dashboard productsState={productsState} plansState={plansState} notices={notices}/>} />
                  <Route path ="account/:account" element = {<Account/>} />
                  <Route path ="account/:account/billing" element = {<Billing/>} />
                  <Route path ="account/card_setting" element = {<CardSetting/>} />
                  <Route path ="contact" element={<Contact/>}/>
                  <Route path ="addons" element={<Addons/>}/>
                  <Route path ="plans" element={<Plans/>}/>
                  <Route path ="payment" element={<Payment/>}/>
                  <Route path ="products" element = {<Products/>}/>
                  <Route path ="users" element = {<Users/>}/>
                  <Route path ="template" element={<Templates/>}/>
                  <Route path="freeimages" element={<FreeImages s3_key={props}/>} />
                  <Route path ="sendmail" element={<SendMail/>}/>
                  
                  <Route path = "product/new" element = {<NewProduct productsState={productsState} plansState={plansState}/>} />
                  <Route path = "product/:productId">
                    <Route path ="dashboard" element = {<Product/>}/>
                    <Route path ="setting" element = {<ProductSetting productsState={productsState}/>} />
                    <Route path ="plans" element={<Plans/>}/>
                    <Route path ="payment" element={<Payment/>}/>
                    <Route path ="addons" element={<ProductAddons/>}/>
                    <Route path =":slug/edit" element = {<Page/>} />
                    <Route path =":slug/preview" element = {<PagePreview/>} />
                    <Route path =":slug/page_redirect" element = {<PageEditRedirect/>} />
                    <Route path="images" element={<Images s3_key={props}/>} />
                    <Route path ="menus" element = {<Menus/>} />
                    <Route path ="blocks" element = {<ShareBlocks/>} />
                    <Route path ="block/:blockId" element = {<ShareBlock/>} />
                    <Route path ="header/edit" element = {<HeaderSections/>} />
                    <Route path ="footer/edit" element = {<FooterSections/>} />
                    {/*<Route path ="contact/edit" element = {<ContactSections/>} />*/}
                    <Route path ="contact" element={<ProductContact/>} />
                    <Route path ="templates" element={<Templates/>} />
                    <Route path ="customforms" element={<CustomForms/>} />
                    <Route path ="trashbox" element={<TrashBox/>} />
                  </Route>
                  
                  <Route path ="notices" element={<Notices/>}/>
                  <Route path ="notice/new" element={<NewNotice/>}/>
                  <Route path ="notice/:noticeId" element={<Notice/>}/>
                  <Route path ="notice/:noticeId/edit" element={<EditNotice/>}/>
                  
                  <Route path ="guide" element={<Guide/>}/>
                  <Route path ="question" element={<Question/>}/>
                </Routes>
                
              </div>
              <Footer />
              
            </div>
          </div>
          
          <style>
            {`
              .react-colorful__pointer {
                width: 13px;
                height: 13px;
              }
            `}
          </style>
        </>
      :
        <>
          <div className="main_area">
            <Routes>
              <Route path ="price" element={<Addons/>} />
              <Route path ="templates" element={<Templates/>}/>
              <Route path ="inquiry" element={<Inquiry/>} />
              <Route path = ":productId">
                <Route path ="contact" element={<ProductContact/>} />
              </Route>
            </Routes>
          </div>
        </>
      }
    
    </>
  );
});

// <Route path ="/" element={<Toppage/>} />

// <Route path = "product">
//   <Route path = ":productId/edit" element = {<Product/>} />
//   <Route path = ":productId/setting" element = {<ProductSetting/>} />
// </Route>

// <Route element={<Layout/>}>



// <Route path =":slug/edit" element = {
//   <SaveDataProvider>
//     <Page/>
//   </SaveDataProvider>
// } />
// <Route path="images" element={<Images s3_key={props}/>} />
// <Route path ="menus/setting" element = {<Menus/>} />
// <Route path ="sharings" element = {<Sharings/>} />
// <Route path ="contact/preview" element={<ProductContact/>} />
// <Route path ="block/:blockId" element = {
//   <SaveDataProvider>
//     <ShareBlock/>
//   </SaveDataProvider>
// } />
// <Route path ="header/sections" element = {
//   <SaveDataProvider>
//     <HeaderSections/>
//   </SaveDataProvider>
// } />
// <Route path ="footer/sections" element = {
//   <SaveDataProvider>
//     <FooterSections/>
//   </SaveDataProvider>
// } />