import React, { useContext } from "react"
import { StateContext } from '@/providers/StateProviders';
import { BiX } from "react-icons/bi";
import { RangeSpin } from '@/editerMenu/RangeSpin';
import { RangeSpinSplit } from '@/editerMenu/RangeSpinSplit';
import { AddonJudg } from '@/addon/AddonJudg';


export const PartGlobalSetting = React.memo(({ partState, InputChange, margins, marginChange, marginClear, 
  Clear, setStates, setOpenSidebar }) => {
  
  const { currentUser } = useContext(StateContext);
  const { part, setPart } = partState
  
  // console.log("パーツ設定", part.id,  "レンダ");
  
  // marginの分割
  // let margins = part?.margin ? part.margin.split("／") : ["",""];
  
  const closeHandler = ()=> {
    setOpenSidebar("");
  };
  
  const part_align = [
    {name: "左側", value: "left"},
    {name: "中央", value: "center"},
    {name: "右側", value: "right"},
  ]
  
// <div className="cover" onClick={CloseHandler}/>
  return (
    <>
      <div className="items_bar">

        <div className="items_bar_top">
          <div className="sidebar_title">
            パーツ設定
            <div className="close_btn" onClick={closeHandler}><BiX/></div>
          </div>
        </div>

        <div className="items_box">
        
          <label>ステータス</label>
          <form className="radio_box" id="status">
            {["公開", "非公開"].map((item) => {
              return (
                <label key={item}
                  className={item == part.status ? "is_on": ""} 
                  // style={{border: item == part.status && "1px solid #00ff2c"}}
                >
                  <input
                    type="radio"
                    id={item}
                    name="status"
                    value={item}
                    onChange={InputChange}
                    checked={item == part.status}
                  />
                  {item}
                </label>
              );
            })}
          </form>

          <label>横幅（{part.width ||""}%）
            <div className="btn_clear" type="button" onClick={()=>Clear("width")}>クリア</div>
          </label>
          <RangeSpin
            obj={part}
            setObj={setPart}
            column="width"
            objValue={part.width ||100} 
            InputChange={InputChange}
            min="10"
            setStates={setStates}
          />

          <label>高さ（{part.height ||""}px）
            <div className="btn_clear" type="button" onClick={()=>Clear("height")}>クリア</div>
          </label>
          <RangeSpin
            obj={part}
            setObj={setPart}
            column="height"
            objValue={part.height ||415}
            InputChange={InputChange}
            max="800"
            min="30"
            setStates={setStates}
          />
          
          <label>文字の整列
            <div className="btn_clear" type="button" onClick={()=>Clear("align")}>クリア</div>
          </label>
          <form className="radio_box" id="align">
            {part_align.map((item) => {
              return (
                <label key={item.value}
                  className={item.value == part.align ? "is_on": ""} 
                >
                  <input
                    type="radio"
                    id="align"
                    name="align" 
                    value={item.value}
                    onChange={InputChange}
                    checked={item.value == part.align}
                  />
                  {item.name}
                </label>
              );
            })}
          </form>
          
          <label>パーツの位置
            <div className="btn_clear" type="button" onClick={()=>Clear("position")}>クリア</div>
          </label>
          <form className="radio_box" id="position">
            {part_align.map((item) => {
              return (
                <label key={item.value}
                  className={item.value == part.position ? "is_on": ""} 
                >
                  <input
                    type="radio"
                    id="position"
                    name="position" 
                    value={item.value}
                    onChange={InputChange}
                    checked={item.value == part.position}
                  />
                  {item.name}
                </label>
              );
            })}
          </form>

          <label>外側のスペース【上】（{margins[0] ||""}px）
            <div className="btn_clear" type="button" onClick={()=>marginClear("margin_top")}>クリア</div>
          </label>
          <RangeSpinSplit
            obj={part}
            setObj={setPart}
            InputChange={marginChange}
            targetName="margin"
            column="margin_top"
            objValueMain={margins[0] ||10}
            objValueOther={margins[1] ||""}
            beforeAfter="before"
            max="150"
            setStates={setStates}
          />
          
          <label>外側のスペース【下】（{margins[1] ||""}px）
            <div className="btn_clear" type="button" onClick={()=>marginClear("margin_bottom")}>クリア</div>
          </label>
          <RangeSpinSplit
            obj={part}
            setObj={setPart}
            InputChange={marginChange}
            targetName="margin"
            column="margin_bottom"
            objValueMain={margins[1] ||10}
            objValueOther={margins[0] ||""}
            beforeAfter="after"
            max="150"
            setStates={setStates}
          />
          
          <label>内側のスペース（{part.padding ||""}px）
            <div className="btn_clear" type="button" onClick={()=>Clear("padding")}>クリア</div>
          </label>
          <RangeSpin
            obj={part}
            setObj={setPart}
            column="padding"
            objValue={part.padding ||30} 
            InputChange={InputChange}
            max="150"
            setStates={setStates}
          />

          
          <label>シャドウ（{part.box_shadow ||""}px）
            <div className="btn_clear" type="button" onClick={()=>Clear("box_shadow")}>クリア</div>
          </label>
          <RangeSpin
            obj={part}
            setObj={setPart}
            column="box_shadow"
            objValue={part.box_shadow ||1}
            InputChange={InputChange}
            max="60"
            setStates={setStates}
          />
          
          <label>フェードイン開始までの遅延時間
            <div className="btn_clear" type="button" onClick={()=>Clear("delay_seconds")}>クリア</div>
          </label>
          <div className="selectbox">
            <select 
              className="" 
              name="delay_seconds"
              value={part.delay_seconds || ""} 
              onChange={InputChange}
            >
              <option value={null}>未設定</option>
              <option value={300}>0.3秒後に開始</option>
              <option value={600}>0.6秒後に開始</option>
              <option value={900}>0.9秒後に開始</option>
              <option value={1200}>1.2秒後に開始</option>
              <option value={1500}>1.5秒後に開始</option>
              <option value={1800}>1.8秒後に開始</option>
              <option value={2100}>2.1秒後に開始</option>
            </select>
          </div>
          
          {currentUser.admin &&
            <>
              <label>フェードインタイプ
                <div className="btn_clear" type="button" onClick={()=>Clear("fadein")}>クリア</div>
              </label>
              <input 
                className="input"
                type="text"
                name="fadein"
                value={part.fadein || ""} 
                onChange={InputChange}
              />
              
              <label>パーツタイプ
                <div className="btn_clear" type="button" onClick={()=>Clear("class_name")}>クリア</div>
              </label>
              <input 
                className="input"
                type="text"
                name="class_name"
                value={part.class_name || ""} 
                onChange={InputChange}
              />
            </>
          }
          
          <AddonJudg terms={/ビジネス|制作代行/} boolean={true}>
            <label>【id】追加
              <div className="btn_clear" type="button" onClick={()=>Clear("add_id")}>クリア</div>
            </label>
            <input 
              className="input" 
              type="text"
              name="add_id"
              value={part?.add_id || ""} 
              onChange={InputChange}
              placeholder="追加するidを入力..."
            />
            
            <label>【class】追加
              <div className="btn_clear" type="button" onClick={()=>Clear("add_class")}>クリア</div>
            </label>
            <input 
              className="input" 
              type="text"
              name="add_class"
              value={part?.add_class || ""} 
              onChange={InputChange}
              placeholder="追加するclassを入力..."
            />
          </AddonJudg>
            
        </div>

      </div>
    </>
  );
});