import React, { useState, useEffect, useContext } from 'react';
import { StateContext } from '@/providers/StateProviders';
// import { SectionSetting } from '@/section/SectionSetting';
import { Normal } from '@/section/type/Normal';
import { Flowing } from '@/section/type/Flowing';
import { Switch } from '@/section/type/Switch';
import { Slide } from '@/section/type/Slide';
import { SectionRemove } from '@/section/SectionRemove';
import { BiChevronUp, BiChevronDown, BiTrash, BiPlus } from "react-icons/bi";

import { useSetRecoilState } from 'recoil'
import { sectionsStatesAtom, sectionStatesAtom, blocksStatesAtom, sharingAtom, sectionIndexAtom, openSidebarAtom } from '@/providers/Atoms';


export const Section = React.memo(({ section_id, sectionsState, orderUp, orderDown, sharing, index }) => {
  
  // console.log("セクション", section_id, "レンダ");
  
  const { client } = useContext(StateContext);
  // const clickSection = useRecoilValue(clickSectionAtom)
  
  const setOpenSidebar = useSetRecoilState(openSidebarAtom);
  const setSharing = useSetRecoilState(sharingAtom);
  const setSectionIndex = useSetRecoilState(sectionIndexAtom);
  const setSectionsStates = useSetRecoilState(sectionsStatesAtom);
  const setSectionStates = useSetRecoilState(sectionStatesAtom)
  const setBlocksStates = useSetRecoilState(blocksStatesAtom)

  const { sections, setSections } = sectionsState
  
  const [ section, setSection ] = useState([]);
  
  const [ blocks, setBlocks ] = useState([]);
  const blocksState = { 
    blocks, setBlocks
  };
  
  useEffect(() => {
    client.get(`/api/v1/sections/${section_id}`)
    .then(resp => {
      setSection(resp.data.section);
      setBlocks(resp.data.blocks);
    })
    .catch(e => {
      console.log(e);
    });
  }, []);
  
  
  // セクション削除用のモーダル
  const [modal, setModal] = useState(false);
  
  const openModal = () => {
    setModal(true);
  };
  

  // クリックしたセッションをセット
  const sectionClickHandler = () => {
    setSectionStates([ section, setSection ]);
    setBlocksStates([ blocks, setBlocks ]);
  }
  
  // セクション追加サイドバーを表示
  const newSectionHandler = () => {
    setSectionsStates([ sections, setSections ]);
    setSharing(sharing);
    setSectionIndex(index);
    setOpenSidebar("newSection");
  }

  
  const selectSection = () => {
    
    const type = section?.section_type || ""
    
    switch(type) {
      case "":
        return <Normal section_id={section_id} blocksState={blocksState}/>;
        break;
      case "Slide":
        return <Slide section={section} blocksState={blocksState} type="loop" key={`Slide_${section.id}`}/>;
        break;
      case "Fade":
        return <Slide section={section} blocksState={blocksState} type="fade" key={`Fade_${section.id}`}/>;
        break;
      case "Flowing":
        return <Flowing section={section} blocksState={blocksState}/>;
        break;
      case "Switch":
        return <Switch section_id={section_id} blocksState={blocksState}/>;
        break;
      default:
        return <div>セクションがありません。</div>;
    };
  };


  return (
    <>
      { blocks.length > 0 &&
        <>
          <div onClick={sectionClickHandler}>
            {selectSection()}
          </div>
            
          <button className="btn_section_add" onClick={newSectionHandler}>
            <span><BiPlus/>ブロックを追加</span>
          </button>
          
          <div className="order_btn">
            <button onClick={()=>orderUp(index)}><BiChevronUp/></button>
            <button onClick={()=>orderDown(index)}><BiChevronDown/></button>
            <button className="delete_section" id={`section_${section_id}_delete_btn`} onClick={openModal}><BiTrash/></button> 
          </div>
          
          {modal &&
            <SectionRemove
              selectSection={selectSection}
              section={section}
              setSections={setSections}
              setModal={setModal} 
            />
          }
        </>
      }
    </>
  );
});


// {modal &&
// <div className="modal modal_delete" onClick={()=>setModal(false)}>
//     <div className="modal_window" onClick={(e) => e.stopPropagation()}>
//       <div className="modal_content">
//         {selectSection()}
//       </div>
//       <div className="btn_area">
//         <button className="back_btn" onClick={()=>setModal(false)}>もどる</button>
//         <button className="delete_btn" onClick={deleteSection}>削除する</button>
//       </div>
//     </div>
//   </div>
// }

// {/* 
// { clickSection == section_id &&
//   <SectionSetting
//     sectionState={sectionState}
//     // sectionsState={sectionsState}
//     blocksState={blocksState}
//   />
// }
// */}