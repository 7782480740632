import React, { useState, useContext } from "react"
import { StateContext } from '@/providers/StateProviders';
import { ToastContext } from '@/providers/ToastProvider';
// import { LinkSelect } from '@/part/LinkSelect';
import { BiTrash, BiLinkAlt, BiCaretUp, BiCaretDown, BiPlus, BiAddToQueue } from "react-icons/bi";
import { RiArrowUpDownLine } from "react-icons/ri";

import { useSetRecoilState } from 'recoil'
import { openSidebarAtom } from '@/providers/Atoms';


export const PartSubmenu = React.memo(({ partState, partsState, column, InputChange, 
  updatePart, CloseHandler, openState, Clear, index }) => {
    
  const setOpenSidebar = useSetRecoilState(openSidebarAtom);

  const { client } = useContext(StateContext);
  const { setToastObj } = useContext(ToastContext);
  // const setSaveData = useSetRecoilState(saveDataAtom)
  
  const { part } = partState;
  const { parts, setParts } = partsState;
  // const { open, setOpen } = openState;
  
  // console.log("パーツサブメニュー", "レンダ",`${part.id}`);
  
  // セーブデータを更新
  // const SaveDataAddup = (newData) => {
  //   setSaveData((prev)=> (
  //     {...prev, parts: [ ...prev.parts, newData]}
  //   ));
  //   console.log("セーブデータ更新")
  // };
  

  // 新規追加初期値
  const new_date = {
    block_id: part.block_id,
    column: column,
  };
  
  // テキストパーツ
  const text_date = {
    block_id: part.block_id,
    component_name: "Text_A",
    column: column,
  };
  
  // 見出しパーツ
  const Headline_date = {
    block_id: part.block_id,
    component_name: "Headline_A",
    class_name: "headline_1-6",
    column: column,
  };
  
  // 画像パーツ
  const Image_date = {
    block_id: part.block_id,
    component_name: "Image_A",
    column: column,
  };
  
  // ボタンパーツ
  const Btn_date = {
    block_id: part.block_id,
    component_name: "Button_A",
    column: column,
  };
  
  
  //並び順を保存
  const OrderUpdate = (objs) => {
    setParts(objs);//並び替え後をセット
    
    client.patch(`/api/v1/parts/order`, {parts: objs})
    .then(resp => {
      console.log("並替え保存");
    })
    .catch(e => {
      setToastObj({message: "【エラー】並び替えの保存に失敗しました。", color: "#ffedab"});
    });
  };
  

  // 新規追加（後に追加）
  const addPart = (date) => {
    client.post('/api/v1/parts', date)
    .then(resp => {
      const new_objs = [...parts];
      new_objs.splice(index+1, 0, resp.data)
      OrderUpdate(new_objs);
      // SaveDataAddup(resp.data);
      // setToastObj({message: "パーツを追加しました", color: "#D1E7DD"});
    })
    .catch(e => {
      setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
      console.log(e)
    })
  };
  
  //新規追加（前に追加）
  const addPartBefore = (date) => {
    client.post('/api/v1/parts', date)
    .then(resp => {
      const new_objs = [...parts];
      new_objs.splice(index, 0, resp.data)
      OrderUpdate(new_objs);
    })
    .catch(e => {
      setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
      console.log(e)
    })
  };
  
  // 複製（後に追加）
  const copyPart = () => {
    client.post('/api/v1/parts/copy', part)
    .then(resp => {
      const new_objs = [...parts];
      new_objs.splice(index+1, 0, resp.data)
      OrderUpdate(new_objs);
    })
    .catch(e => {
      setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
      console.log(e)
    })
  };
  
  // 複製（前に追加）
  const copyPartBefore = () => {
    client.post('/api/v1/parts/copy', part)
    .then(resp => {
      const new_objs = [...parts];
      new_objs.splice(index, 0, resp.data)
      OrderUpdate(new_objs);
    })
    .catch(e => {
      setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
      console.log(e)
    })
  };
  
  
  // 削除
  // const deletePart = () => {
  //   const sure = window.confirm(`（${part.id}）このパーツを本当に削除しますか?`);
  //   if (sure) {
  //     client.delete(`/api/v1/parts/${part.id}`)
  //     .then(resp => {
  //       setParts((prev) =>
  //         prev.filter((obj) => obj.id !== part.id)
  //       );
  //       // setToastObj({message: "パーツを削除しました。", color: "#ffedab"});
  //     })
  //     .catch(e => {
  //       setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
  //       console.log(e);
  //     });
  //   }
  // };
  
  // 一旦削除
  const removePart = () => {
    const sure = window.confirm(`（${part.id}）このパーツを本当に削除しますか?`);
    if (sure) {
      // client.patch(`/api/v1/parts/remove/${part.id}`)
      client.patch(`/api/v1/parts/${part.id}`, { ...part, status: "削除" })
      .then(resp => {
        setParts((prev) =>
          prev.filter((obj) => obj.id !== part.id)
        );
      })
      .catch(e => {
        setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
        console.log(e);
      });
    }
  };
  
  
  // フォーカスを外す
  const removeFocus = ()=> {
    const elem = document.activeElement;
    elem.blur();
  };
  
  
  //並び順をUP
  const orderUp = () => {
    const objs = [...parts]
    const removed = objs.splice(index, 1); //ドラッグ開始要素の削除
    // setPart({...removed[0], order: index-1});
    objs.splice(index-1, 0, removed[0]); //ドロップした箇所に挿入
    // setParts(objs); //並び替え後をセット
    // SaveOrderUpdate(objs);
    OrderUpdate(objs); //並び替え後を保存
  };
  
  const orderDown = () => {
    const objs = [...parts]
    const removed = objs.splice(index, 1); //ドラッグ開始要素の削除
    // setPart({...removed[0], order: index+1});
    objs.splice(index+1, 0, removed[0]); //ドロップした箇所に挿入
    // setParts(objs); //並び替え後をセット
    // SaveOrderUpdate(objs);
    OrderUpdate(objs); //並び替え後を保存
  };
  
  
  // リンク設定オープン
  const linkSettinOpen = ()=> {
    setOpenSidebar("partLink");
    removeFocus();
  };
  
  // const [ open, setOpen ] = useState(false);
  
  
  return (
    <>
      <div className="part_submenu">

        <div className="drop_btn">
          <button className="menu_btn" onClick={removeFocus}><BiPlus/></button>
          <div className="drop_box drop_box_center">
            <div className="drop_list">
              <div className="drop_item" onClick={()=>addPartBefore(new_date)}><span><BiPlus/>前にパーツを追加</span></div>
              <div className="drop_item" onClick={()=>addPartBefore(Headline_date)}><span><BiPlus/>見出し</span></div>
              <div className="drop_item" onClick={()=>addPartBefore(text_date)}><span><BiPlus/>テキスト</span></div>
              <div className="drop_item" onClick={()=>addPartBefore(Image_date)}><span><BiPlus/>画像</span></div>
              <div className="drop_item" onClick={()=>addPartBefore(Btn_date)}><span><BiPlus/>ボタン</span></div>
              <div className="drop_item" onClick={copyPartBefore}><span><BiAddToQueue/>パーツを複製</span></div>
            </div>
          </div>
        </div>
        
        <div className="tooltip">
          <button className="menu_btn" onClick={linkSettinOpen}><BiLinkAlt/></button>
          <div className="tooltip_text">リンク設置</div>
        </div>

        <div className="drop_btn">
          <button className="menu_btn"><RiArrowUpDownLine/></button>
          <div className="drop_box drop_box_center">
            <div className="drop_list">
              <div className="drop_item" onClick={orderUp}><span><BiCaretUp/>前に移動</span></div>
              <div className="drop_item" onClick={orderDown}><span><BiCaretDown/>後に移動</span></div>
            </div>
          </div>
        </div>
        
        <div className="tooltip">
          <button className="menu_btn" onClick={removePart}><BiTrash/></button>
          <div className="tooltip_text">パーツの削除</div>
        </div>

      </div>
      
      <div className="part_submenu part_add">
        <div className="drop_btn">
          <button className="menu_btn" onClick={removeFocus}><BiPlus/></button>
          <div className="drop_box drop_box_center">
            <div className="drop_list">
              <div className="drop_item" onClick={()=>addPart(new_date)}><span><BiPlus/>後にパーツを追加</span></div>
              <div className="drop_item" onClick={()=>addPart(Headline_date)}><span><BiPlus/>見出し</span></div>
              <div className="drop_item" onClick={()=>addPart(text_date)}><span><BiPlus/>テキスト</span></div>
              <div className="drop_item" onClick={()=>addPart(Image_date)}><span><BiPlus/>画像</span></div>
              <div className="drop_item" onClick={()=>addPart(Btn_date)}><span><BiPlus/>ボタン</span></div>
              <div className="drop_item" onClick={copyPart}><span><BiAddToQueue/>パーツを複製</span></div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );

});


// {/*
// <div className="tooltip">
//   <button className="menu_btn keyboard_btn" onClick={removeFocus}><MdOutlineKeyboardHide/></button>
//   <div className="tooltip_text">キーボード閉る</div>
// </div>
// */}

// {/*
// <div className="drop_btn">
//   <button className="menu_btn"><BiDotsVerticalRounded/></button>
//   <div className="drop_box">
//     <div className="drop_list">
//       <div className="drop_item" onClick={deletePart}><span><BiTrash/>削除</span></div>
//     </div>
//   </div>
// </div>
// */}

//       {/* 
// { open =="Link" &&
// <LinkSelect
//   obj={part}
//   InputChange={InputChange}
//   update={updatePart}
//   setOpen={setOpen}
//   Clear={Clear}
// />
// }
// */}

// <button className="menu_btn" onClick={orderUp}><BiCaretUp/></button>
// <button className="menu_btn" onClick={orderDown}><BiCaretDown/></button>

// <button className="menu_btn" onClick={deletePart}><BiTrash/></button>
// <button className="menu_btn" onClick={CloseHandler}><BiX/></button>