import React from "react"
import { outlineCSS, textCSS } from '@/part/type/Style';
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Textarea } from '@/text/Textarea';


export const CopyRight_A = React.memo(({ part, textStates, textHandlers }) => {

  const { title, setTitle } = textStates;
  const { titleHandler } = textHandlers
  
  const copyrightCSS = css`
    ${outlineCSS(part)}
    ${textCSS(title)}
  `
  
  return (
    <>
      <div
        className={`copyright_1 ${part.class_name}`}
        css={copyrightCSS}
      >
        <Textarea
          part_id={part.id}
          text_type="title"
          obj={title}
          setObj={setTitle}
          placeholder="Copyright © PAGE STOCK All Rights Reserved"
          clickHandler={titleHandler}
        />
      </div>
    </>
  );
  
});